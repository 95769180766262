<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-25 09:52:51
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 15:21:54
 * @FilePath: \dataview-next\src\manage-views\org\user\components\companytree.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="user-company-tree" :style="{width: isOpen ? '' : '0px',padding: isOpen ? '' : '0px'}">
    <div class="expand-switch" :class="{ 'is-open': isOpen }" @click="handleClick">
      <svg class="expand-icon">
        <use xlink:href="#iconshuangjiantou_shang"/>
      </svg>
    </div>
    
    <div v-if="isOpen">
      <el-input
        placeholder="输入关键字进行筛选"
        v-model="filterText">
      </el-input>
      <el-tree
        class="filter-tree"
        :data="treeData"
        :props="defaultProps"
        default-expand-all
        :filter-node-method="filterNode"
        @node-click="handleNodeClick"
        ref="tree">
        <template #default="{ node, data }">
          <div class="archi-label">
              <span class="archi-label-svg">
                <svg aria-hidden="true" class="color-icon">
                  <use :xlink:href="getIcon(data)"/>
                </svg>
                <el-tooltip
                  v-if="node && data[defaultProps.label].length > 12"
                  :content="node ? data[defaultProps.label] : ''"
                  effect="dark"
                  :open-delay="500"
                  placement="top"
                >
                  <span>{{ node ? data[defaultProps.label] : '' }}</span>
                </el-tooltip>
                <span v-else>{{ node ? data[defaultProps.label] : '' }}</span>
              </span>
          </div>
        </template>
      </el-tree>
    </div>

  </div>
</template>

<script>
import {Tree} from 'element-ui'
import {getDeptSelect} from '@/api/saasManage'

export default {
  name: 'CompanyTree',
  components: {
    'el-tree': Tree,
  },
  data() {
    return {
      isOpen: true,
      filterText: '',
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    // 树筛选事件
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getIcon(data) {
      const group = [1, 2]
      const company = [3, 4]
      const project = [5, 6]
      const section = [7]
      if (!data) {
        return '#iconjituan'
      }
      if (group.indexOf(+data.type) !== -1) {
        return '#iconjituan'
      } else if (company.indexOf(+data.type) !== -1) {
        return '#icongongsi'
      } else if (project.indexOf(+data.type) !== -1) {
        return '#iconxiangmu'
      } else if (section.indexOf(+data.type) !== -1) {
        return '#iconbumen'
      }
    },
    getData() {
      this.loading = true;
      getDeptSelect().then(res => {
        if (res.data && res.data.code !== 200) {
          return false;
        }
        this.treeData = res.data.data;
        this.loading = false;
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
    handleNodeClick(row) {
      // todo
      this.$emit('nodeClick', row.id);
    },
    handleClick() {
      this.isOpen = !this.isOpen;
      this.$emit('onOpen', this.isOpen)
    }
  },

};
</script>
<style lang="less" scoped>
.user-company-tree {
  transition: width .5s;
  width: 220px;
  position: relative;
  border-right: 1px solid #ebedf0;
  z-index: 1;
  padding: 16px 10px;
  min-height: calc(100vh - 167px);
  background: white;
  height: 100%;

  .shrink-icon {
    right: -6px;
    position: absolute;
    z-index: 999;
    font-size: 22px;
    top: 21px;
  }

  .zhankai-icon {
    right: -20px;
    position: absolute;
    z-index: 999;
    font-size: 22px;
    top: 21px;
  }

  .filter-tree {
    margin-top: 20px;
  }

  .archi-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    -moz-user-select: none; /* Firefox私有属性 */
    -webkit-user-select: none; /* WebKit内核私有属性 */
    -ms-user-select: none; /* IE私有属性(IE10及以后) */
    -khtml-user-select: none; /* KHTML内核私有属性 */
    -o-user-select: none; /* Opera私有属性 */
    user-select: none; /* CSS3属性 */

    .archi-label-svg {
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;

      span {
        flex: 1;
        overflow: hidden;
        padding-right: 6px;
        box-sizing: border-box;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }

    .color-icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
}

.expand-switch {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1;
  width: 25px;
  height: 25px;
  transform: translateX(100%);
  transition: all .5s;
  box-shadow: 2px 2px 5px 3px #ccc;
  border-radius: 0 50% 50% 0;
  background: #fff;

  .expand-icon {
    width: 100%;
    height: 100%;
    transform: rotate(90deg);
    transition: all .5s;
  }
}

.is-open {
  width: 25px;
  height: 25px;
  transform: translateX(50%);
  border-radius: 50%;
  visibility: hidden;

  .expand-icon {
    transform: rotate(-90deg);
    visibility: hidden;
  }
}

&:hover {
  .is-open {
    transition: all .5s;
    visibility: visible;

    .expand-icon {
      visibility: visible;
    }
  }
}

.is-active {
  border-right: 1px solid #f1f1f1;
}
</style>
