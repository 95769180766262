<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-25 10:58:19
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 17:35:50
 * @FilePath: \dataview-next\src\manage-views\org\user\components\Main.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
<div class="user-main-container" :style="{width: isOpen ? 'calc(100% - 240px)' : '100%',marginLeft: isOpen ? '8px':''}">
  <Header @onSearch='onSearch' @onRefresh='onRefresh' title=""></Header>
    <div class="user-main">
    <div class='main-title'>
      <div class="main-label">用户管理</div>
      <div >
        <el-button size='mini' type="primary" icon="el-icon-plus" @click="dialog=!dialog" v-if="judgingAPermission(['user.store'])">新增</el-button>
        <!-- <el-button v-if="parentSelectId && parentSelectId.length &&judgingAPermission(['user.delete'])" size='mini' type="danger" icon="el-icon-delete-solid" @click="onSelectDelete()">删除</el-button> -->
      </div>
    </div>
    <el-table
    v-loading='loading'
    :data="userData"
    :header-cell-style="{background:'#fafafa'}"
    @selection-change="onSelectChange"
    class="user-table"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="姓名" prop="name" width="150px"></el-table-column>
      <el-table-column label="部门" prop="type" align="left">
        <template #default="{ row }">
          <span v-if="row.saas_department_user && row.saas_department_user.length">{{ row.saas_department_user[0].department_info.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="created_at" align="left" width="150px"></el-table-column>
      <el-table-column label="操作"  align="center" width='200px'>
        <template #default="{ row }"  >
          <el-button size="mini" type="text" @click="onEdit(row)" v-if="judgingAPermission(['user.update'])">修改</el-button>
          <el-button size="mini" type="text" @click="rePwd(row)" v-if="judgingAPermission(['user.repwd'])">重置密码</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 页脚 -->
    <el-pagination
      @size-change="onSize"
      @current-change="onPage"
      :current-page="+pager.page"
      :page-sizes="[15, 20, 50, 100, 200]"
      :page-size="+pager.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="+pager.total"
    >
    </el-pagination>
    <!-- 编辑抽屉 -->
    <EditDrawer 
    :drawer='drawer' 
    :editData='editData' 
    @drawerClose='drawerClose' 
    @drawerSave='drawerSave'>
    </EditDrawer>
    <!-- 新增弹窗 -->
    <AddDialog 
    :dialog='dialog'
    @dialogClose='dialogClose' 
    @dialogSave='dialogSave' >
    </AddDialog>
    <EditPwd
    :editData='editData'
    :dialogPwd='dialogPwd'
    @pwdClose='dialogPwdClose'
    @pwdSave='pwdSave'
    >
    </EditPwd>
  </div>
</div>
</template>

<script>
import Header from '@/manage-views/components/header/Index.vue'
import { getUser, detUser } from '@/api/saasManage'
import { judgingAPermission } from '@/utils/tools'
import EditPwd from './EditPwd';
import EditDrawer from './EditDrawer.vue';
import AddDialog from './AddDialog.vue';
export default {
  name:'Main',
  data() {
    return {
      judgingAPermission: judgingAPermission,
      dialogPwd:false, //重置密码弹窗
      isOpen :true, 
      name: '',
      phone: '',
      departmentId:'',
      options:[{
        value: '0',
        label: '停用'
      }, {
        value: '1',
        label: '正常'
      }],
      parentSelectId:[],
      loading:false,
      userData:[],
      drawer:false,
      dialog:false,
      editData:{},
      pager: {
        page: 1,
        size: 15,
        total: 0
      },
      search: [],
    };
  },
  components:{
    EditDrawer,
    AddDialog,
    EditPwd,
    Header
  },
  mounted(){
    this.getData();
  },
  methods: {
    /**
     * @desc : 重置密码
     */
    rePwd(row){
      this.editData = row;
      this.dialogPwd = !this.dialogPwd;
    },
    pwdSave(){
      this.dialogPwd = false;
    },
    /**
     * 左侧树选择
     */
    nodeClick(id){
      this.search = '';
      this.departmentId = id;
      this.getData()
    },
    dialogPwdClose(){
      this.dialogPwd = false;
    },
     /**
     * @desc:关闭新增弹窗
     */
    dialogClose(){
      this.dialog = !this.dialog;
    },
    /**
     * @desc:新增弹窗点击保存按钮
     */
    dialogSave(){
      this.dialog = !this.dialog;
      this.getData();
    },
    /**
     * @desc:修改抽屉点击保存按钮
     */
    drawerSave(){
      this.drawer = false;
      this.getData();
    },
    /**
     * @desc:关闭编辑抽屉
     */
    drawerClose(){
      this.drawer = !this.drawer;
    },
    pwdClose(){
      this.dialogPwd = false;
    },
    /**
     * @desc:打开编辑弹窗
     */
    onEdit(row){
      this.editData = row;
      this.drawer = !this.drawer;
    },
    /**
     * @desc:普通删除
     */
    onDelete(row){
      detUser({id:row.id}).then((res)=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.getData();
      }).catch(() => {
        this.$message.error(`操作错误`)
      });
    },
    /**
     * @desc: 批量删除
     */
    onSelectDelete(){
      detUser({id:this.parentSelectId}).then((res)=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.getData();
      }).catch(() => {
        this.$message.error(`操作错误`)
      });
    },
    onRefresh(){
      this.search = [];
      this.phone = '';
      this.departmentId = '';
      this.name = '';
      this.getData();
    },
     /**
		 * @desc: 分页数量
		 * @param {Number} size
		 */
		onSize(size) {
      this.pager.page = 1;
      this.pager.size = size;
      this.getData();
		},
		/**
		 * @desc: 切换页码
		 * @param {Number} page
		 */
		onPage(page) {
      this.pager.page = page;
      this.getData();
		},
    /**
     * @desc:父表格选择时
     */
    onSelectChange(val){
      this.parentSelectId = [];
      val.forEach(data=>{
        this.parentSelectId.push(data.id);
      })
    },
    /**
     * @desc: 左边树状态
     */
    onOpen(item){
      this.isOpen = item;
    },
    onSearch(data){
      this.name = data;
      this.getData();
    },
    getData(){
      this.loading = true;
      let params = {
        page: this.pager.page,
        size: this.pager.size,
        name: this.name
      };
      if(this.departmentId){
        params.department_id = this.departmentId;
      }
      getUser(params).then(res=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.pager = {
          page: res.data.data.current_page,
          size: res.data.data.per_page,
          total: res.data.data.total
        };
        this.userData = res.data.data.data;
        this.loading = false;
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
  },
};
</script>

<style lang="less">
@import "../../../../css/manageMain.less";
.user-main-container{
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  .user-header {
    border-left:0px ;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0,0,0,.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    position: relative;
    background: #fff;
    border-radius: 2px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    height: 60px;
    justify-content: space-between;
    .user-search{
      display:flex;
      max-width: 300px;
      padding-left: 26px;
      .search-input{
        width: 100%;
        display:flex;
        padding-right: 24px;
        box-sizing: border-box;
        align-items: center;  
        span{
          width: 23%;
        }
        div{
          flex:1;
          padding-left: 5px;
          box-sizing: border-box;
        }
      }
    }
    .search-btn{
      width: 180px;
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;
    } 
  }
  .user-main {
    background-color: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    .user-table{
      width: 100%;
      flex: 1;
      padding: 0 15px;
      overflow: auto;
    }
  }
}
</style>