<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-25 09:20:24
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-08-31 10:23:23
 * @FilePath: \dataview-next\src\manage-views\org\user\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class='user-container'>
    <CompanyTree @onOpen='onOpen' @nodeClick='nodeClick' ></CompanyTree>
    <Main ref='main'></Main>
  </div>
</template>
<script >
import CompanyTree from './components/CompanyTree'
import Main from './components/Main'
export default {
  components: { 
    CompanyTree,
    Main
  },
  methods: {
    onOpen(data){
      this.$refs.main.onOpen(data);
    },
    nodeClick(data){
      this.$refs.main.nodeClick(data);
    }
  }
};
</script>
<style lang='less'>
.user-container{
  display: flex;
  width:100%;
  height:100%;
}
</style>