<template>
  <div class="user-edit-main">
    <el-drawer
      :title="`修改[${form.name}]信息`"
      :visible.sync="drawers"
      :before-close="onClose"
      >
      <div class="form-class">
        <el-form  
        label-width="80px" 
        :model="form" 
        label-position="top"  
        :rules="rules" 
        ref="dialog"
        >

          <el-row>
            <el-col :span="12">
              <el-form-item label="姓名"  prop='name'>
                <el-input class='form-half-input' v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属部门">
                <el-select v-model="departmentName" placeholder="请选择部门" class='form-half-input'>
                  <el-option label="区域一" value="0" class='form-option' >  
                    <el-tree ref="tree" :data="parentData" default-expand-all node-key="id"  @node-click="handleNodeClick"  :props="defaultProps"/>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="岗位">
                <el-select multiple v-model="userPost" placeholder="请选择" class='form-half-input'  @change='postChange'>
                  <el-option v-for="item in postData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号">
                <el-input class='form-half-input' v-model="form.phone"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="角色" prop='userRole'>
                <el-select v-model="userRole" multiple  placeholder="请选择" class='form-half-input' @change='roleChange'>
                  <el-option v-for="item in roleData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮箱地址">
                <el-input class='form-half-input' v-model="form.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="性别">
                <el-radio-group v-model="form.gender">
                  <el-radio-button label="0">男</el-radio-button>
                  <el-radio-button label="1">女</el-radio-button>
                  <el-radio-button label="2">未知</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item class="bottom-btn">
            <el-button size="mini" @click="onClose">取消</el-button>
            <el-button size="mini" type="primary" @click="onSave">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {  RadioGroup, RadioButton, Tree } from 'element-ui';
import { editUser, getPostSelect, getRoleSelect, getDeptSelect } from '@/api/saasManage'
import { deepClone } from '@topology/core';
import { getComponentById } from "@/utils/tools";
export default {
  name:'EditDrawer',
  components: {
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-tree': Tree
  },
  props: {
    drawer:{
      type:Boolean,
      default:false
    },
    editData:{
      type:Object,
      default:() => {}
    }
  },

  data() {
    return {
      roleData:'',
      postData:'',
      drawers:false,
      form:{
      },
      departmentName:'',
      parentData:[],
      userRole:[],
      userPost:[],
      defaultProps: {
        children: "children",
        label: "name",
      },
      rules:{
        name:[
           { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
        phone:[
           { required: true, message: '手机号不能为空', trigger: 'blur' },
        ],
      }
    };
  },
  watch: {
    drawer(newVal){
      this.drawers = newVal;
    },
    editData(newVal){
      if(newVal && newVal.id){
        this.userRole = [];
        this.userPost = [];
        this.form = deepClone(newVal);
        this.form.saas_user_role.forEach((item)=>{
          this.userRole.push(item.role_id);
        })
        this.form.saas_user_post.forEach((item)=>{
          this.userPost.push(item.post_id);
        })
        this.form.saas_department_user.forEach((item)=>{
          this.departmentName = item.department_name;
        })
      }
    }
  },
  mounted() {
    this.getParentData();
  },
  methods: {
      /**
     * @desc : 角色选择改变时
     */
    roleChange(row){
      this.form.saas_user_role = []
      row.forEach(item => {
        this.form.saas_user_role.push({role_id:item,company_id:getComponentById()});
      });
    },
    /**
     * @desc : 岗位
     */
    postChange(row){
      this.form.saas_user_post = [];
      row.forEach(item => {
        this.form.saas_user_post.push({post_id:item,company_id:getComponentById()});
      });
    },
    /**
     * @desc:保存
     */
    onSave(){
      this.$refs['dialog'].validate((valid) => {
        if (!valid) {
          return false;
        }
        // 把用户名称加入选择部门中
        this.form.saas_department_user.forEach(item =>{
          item.saas_user_name = this.form.name
        })
        // this.form.saas_department_user.saas_user_name  = this.form.name
        editUser(this.form,this.form.id).then(res=>{
          this.userRole = [];
          this.userPost = [];
          this.departmentName = '';
          if(res.data && res.data.code !== 200){
            return false;
          }
          this.$emit('drawerSave');
        }).catch(() => {
          this.$message.error(`操作错误`);
        });
      })
      // 

    },
    /**
     * @desc:关闭
     */
    onClose() {
      this.drawers = false;
      this.$emit('drawerClose');
    },
    /**
     * @desc: 树点击
     */
    handleNodeClick(row){
      this.deparId = row.id;
      this.departmentName = row.name;
      this.form.saas_department_user  = [{
        department_id:row.id,
        company_id:getComponentById(),
        department_name:row.name,
      }];
    },
    /**
     * @desc: 获取下拉框的数据
     */
    getParentData(){
      // 部门
      getDeptSelect().then(res=>{
        if(res.data && res.data.code !== 200){ 
          return false;
        }
        this.parentData = res.data.data;
      }).catch(() => {
        this.$message.error(`操作错误`)
      });

      // 角色列表
      getRoleSelect(1).then(res=>{
        if(res.status === 200 && res.data.code === 200){
          this.roleData = res.data.data;
        }
      }).catch(() => {
        this.$message.error(`操作错误`);
      });

      // 岗位列表
      getPostSelect().then(res=>{
        if(res.status === 200 && res.data.code === 200){
          this.postData = res.data.data;
        }
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
  },
};
</script> 
<style lang="less" >
.user-edit-main{
  text-align:left;
  color: #444;
  .form-class{
    padding: 0px 24px 50px 24px;
    font-size: 14px;
    line-height: 1.5;
    height: 100%;
    overflow: auto;
      .form-half-input{
        width:90%;
      }
      .form-input{
        width:95%;
      }
      .form-option{
        height: auto;
        background-color: #fff !important;
      }
  }
  .el-form-item__label{
    padding: 0 0 8px !important;
    width:100% !important;
    text-align: left;
  }
  .bottom-btn{
    margin:0;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    right: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: flex-end;
    padding-right: 43px;
  }
}

</style>