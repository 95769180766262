<template>
  <div class="user-dialog">
    <el-dialog title="新增用户" :fullscreen="dialogFull"  :visible.sync='dialogs' width='800px' :before-close="onClose">
      <template slot="title">
        <div class="avue-crud__dialog__header">
            <span>
            添加用户
            </span>
          <div class="avue-crud__dialog__menu" @click="dialogFull? dialogFull=false: dialogFull=true">
            <i class="el-icon-full-screen"></i>
          </div>
        </div>
      </template>
      <el-form
        label-width="80px"
        :model="form"
        label-position="top"
        class="form-class"
        :rules="rules"
        ref="dialog"
        >

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="姓名"  prop='name'>
                <el-input class='form-input' v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属部门">
                <el-select v-model="deparName" placeholder="请选择部门" class='form-input'>
                  <el-option label="区域一" value="0" class='form-option' style="height: auto;background-color: #fff;" >
                    <el-tree ref="tree" :data="parentData" default-expand-all node-key="id"  @node-click="handleNodeClick"  :props="defaultProps"/>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="手机号" prop='phone'>
                <el-input class='form-input' v-model="form.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="密码" prop="password">
                <el-input class='form-input' show-password v-model="form.password" auto-complete="new-password"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="角色">
                <el-select v-model="userRole" multiple  placeholder="请选择" class='form-input' @change='roleChange'>
                  <el-option v-for="item in roleData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮箱地址">
                <el-input class='form-input' v-model="form.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="性别">
                <el-radio-group v-model="form.gender">
                  <el-radio-button label="0">男</el-radio-button>
                  <el-radio-button label="1">女</el-radio-button>
                  <el-radio-button label="2">未知</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="岗位">
                <el-select multiple v-model="userPost" placeholder="请选择" class='form-input' @change='postChange'>
                  <el-option v-for="item in postData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
        <div slot="footer">
          <el-button size="mini" @click="onClose">取消</el-button>
          <el-button size="mini" type="primary" @click="onSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Tree, RadioGroup, RadioButton } from 'element-ui';
import { addUser, getPostSelect, getRoleSelect, getDeptSelect } from '@/api/saasManage'
import { getComponentById } from "@/utils/tools";
export default {
  name:'AddDialog',
  components: {
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    'el-tree': Tree
  },
  props: {
     dialog:{
      type:Boolean,
      default:false,
    },
  },
  data() {
    return {
      roleData:null, // 角色列表
      postData:null, // 岗位列表
      parentData:[],
      userRole:[],
      userPost:[],
      dialogs:false,
      dialogFull:false,
      form:{
        saas_user_role: [],// 角色
        saas_user_post:[],// 岗位
        saas_department_user:[],// 部门
        email:'', // 邮箱
        phone:'',// 电话
        name:'',//部门名称
        password:'',// 密码
        gender:'0' ,//性别
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
      deparId:'',
      deparName:'', // 用于展示
      rules:{
        name:[
           { required: true, message: '名称不能为空', trigger: 'blur' },
        ],
        phone:[
           { required: true, message: '手机号不能为空', trigger: 'blur' },
        ],
        password:[
           { required: true, message: '密码不能为空', trigger: 'blur' },
        ],
      }
    };
  },
  watch: {
    dialog(newVal){
      this.dialogs = newVal;
    },
  },
  mounted() {
    this.getParentData()
  },
  methods: {
    /**
     * @desc : 角色选择改变时
     */
    roleChange(row){
      this.form.saas_user_role = [];
      row.forEach(item => {
        this.form.saas_user_role.push({role_id:item,company_id:getComponentById()});
      });
    },
        /**
     * @desc : 岗位
     */
    postChange(row){
      this.form.saas_user_post = [];
      row.forEach(item => {
        this.form.saas_user_post.push({post_id:item,company_id:getComponentById()})
      });
    },
    /**
     * @desc 保存按钮
     */
    onSave(){
      this.$refs['dialog'].validate((valid) => {
        if (valid) {
           // 把用户名称加入选择部门中
          if(localStorage.getItem('company_id') == 0){
            this.form.saas_admin = 1;
          }
          addUser(this.form).then(res=>{
            if(res.data && res.data.code !== 200){
              return false;
            }
            this.$emit('dialogSave');
            this.form = {
                saas_user_role: [],// 角色
                saas_user_post:[],// 岗位
                saas_department_user:[],// 部门
                email:'', // 邮箱
                phone:'',// 电话
                name:'',//部门名称
                password:'',// 密码
                gender:'0' ,//性别
            };
          }).catch(() => {
            this.$message.error(`操作错误`);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * @desc:关闭按钮
     */
    onClose() {
      this.dialogs = false;
      this.form = {
        saas_user_role: [],// 角色
        saas_user_post: [],// 岗位
        saas_department_user:[],// 部门
        email:'', // 邮箱
        phone:'',// 电话
        name:'',//部门名称
        password:'',// 密码
        gender:'0' ,//性别
      };
      this.$emit('dialogClose');
    },
    /**
     * @desc:获取上级部门下拉选择框的数据
     * @param
     */
    getParentData(){
      // 部门
      getDeptSelect().then(res=>{
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.parentData = res.data.data;
      }).catch(() => {
        this.$message.error(`操作错误`)
      });

      // 角色列表
      getRoleSelect(1).then(res=>{
        if(res.status === 200 && res.data.code === 200){
          this.roleData = res.data.data;
        }
      }).catch(() => {
        this.$message.error(`操作错误`);
      });

      // 岗位列表
      getPostSelect().then(res=>{
        if(res.status === 200 && res.data.code === 200){
          this.postData = res.data.data;
        }
      }).catch(() => {
        this.$message.error(`操作错误`);
      });
    },
    /**
     * @desc: 下拉树选择的数据
     */
    handleNodeClick(row){
      this.deparId = row.id;
      this.deparName = row.name;
      this.form.saas_department_user  = [{
        saas_user_name : this.form.name,
        department_id: row.id,
        company_id: getComponentById(),
        department_name: row.name,
      }];
    }
  },
};
</script>
<style lang="less">
.user-dialog{
  .form-class{
    height:350px;
    overflow:auto;
  }
}
</style>
<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
:deep(.el-form) {
  .el-input{
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-transition-delay: 99999s;
      -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
    }
  }
}
</style>
