<!--
 * @Author: 朱廷果 1028509503@qq.com
 * @Date: 2022-08-31 10:47:48
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-30 15:23:03
 * @FilePath: \dataview-next\src\manage-views\views\org\user\components\editPwd.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="pwd-container">
    <el-dialog width="800px" :visible.sync="drawers" :before-close="onClose">
      <template slot="title">
        <div class="avue-crud__dialog__header">
            <span>
            修改密码
            </span>
          <div class="avue-crud__dialog__menu" @click="dialogFull? dialogFull=false: dialogFull=true">
            <i class="el-icon-full-screen"></i>
          </div>
        </div>
      </template>
      <el-form :model="form" :rules="rules">
        <el-form-item prop="password" label="新密码：">
          <el-input
            type="password"
            v-model="form.password"
            placeholder="请输入新的密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码：" prop="confirmation">
          <el-input
            type="password"
            v-model="form.confirmation"
            placeholder="请重复新的密码"
            @input="checkConfirm"
          ></el-input>
        </el-form-item>
      </el-form>
      <p :class="attention_color">{{ attention }}</p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onSave()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { changeSaasPassword } from "@/api/saas";
import { getCompanyId } from "@/utils/tools";
export default {
  name:'EditPwd',
  props: {
    editData:{
      type: Object,
      default: ()=>{}
    },
    dialogPwd:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      form:{
        password: '',
        confirmation: '',
      },
      drawers:false,
      attention: '',
      attention_color: 'black',
      rules:{
        password:[
          { required: true, message: '新密码不能为空', trigger: 'blur' },
        ],
        confirmation:[
          { required: true, message: '请确认密码', trigger: 'blur' },
        ]
      }
    };
  },
  watch: {
    dialogPwd(newVal){
      this.drawers = newVal;
    },
  },
  methods: {
    checkConfirm(){
      if (!this.form.password || !this.form.confirmation) {
        this.attention = '新密码不能为空'
        this.attention_color = 'red'
      } else if (this.form.password != this.form.confirmation) {
        this.attention = '两次新密码输入不一致'
        this.attention_color = 'red'
      } else {
        this.attention = '校验成功'
        this.attention_color = 'green'
        return true
      }
      return false
    },
    onSave(){
      let params = {
        user_id:this.editData.id,
        password:this.form.password,
        password_confirmation:this.form.confirmation,
        company_id:getCompanyId()
      };
      changeSaasPassword(params).then(res=>{
        console.log(res)
        if(res.data && res.data.code !== 200){
          return false;
        }
        this.form = {
          password: '',
          confirmation: '',
        }
        this.$message.success(res.data.msg);
        this.$emit('pwdSave');
      }).catch((res) => {
        this.$message.error(res.data.msg);
      });
    },
    onClose(){
      this.drawers = false;
      this.form = {};
      this.$emit('pwdClose');
    }
  },
};
</script>
<style lang="less" scoped>
.red {
  color: red;
}
.green {
  color: green;
}
.black {
  color: black;
}
</style>
<style lang="less" scoped>
@import "../../../../css/manageAdd.less";
</style>
